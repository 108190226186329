import React, { useState, useEffect } from 'react';
import * as Icons from 'react-icons/fa';
import {
  fieldValue,
  readableFormat,
  sortWebSourceFields,
  webSourceFieldLabel,
} from '../../helpers/caseForms/utilHelpers';
import EditIcon from '../../assets/Images/edit.svg';
import TrashIcon from '../../assets/Images/trash.svg';
import PopUpConfirmation from '../PopupConfirmation';
import Feedback from '../Feedback';
import { AwardClassificationOptions, AwardTypes, CaseStatus } from '../../constants';
import { getWebSourceSiteLabel, webSourceFields } from '../../helpers/caseForms/webSourceFields';
import { parseMarkdown } from '../../helpers/markdown';

const ConfirmationStep = ({
  data,
  index,
  numSteps,
  isLast,
  swapSteps,
  direction,
  editSource,
  deleteSource,
  expandAll,
  noSwaps,
  hideExpand,
  feedbackComments,
  status,
  awardClassificationOption,
  disableEdit,
  allSteps = [],
  swapAdStep,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [swapDirection] = useState(direction || 0);
  const [translation, setTranslation] = useState('');
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  useEffect(() => {
    if (swapDirection === 0) return;
    const className = swapDirection > 0 ? 'translate-y-28' : '-translate-y-28';

    setTranslation(className);
  }, [swapDirection]);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  // If the source is the first source and a escort ad allow swapping of ad. Convert escort ad to source and vice versa
  // let swapAdButton = <></>;
  // if (index === 0 && data.body?.escortAd) {
  //   swapAdButton = (
  //     <button
  //       className="m-auto"
  //       onClick={() => {
  //         swapSteps(index, 0);
  //       }}
  //       type="button">
  //       <Icons.FaExchangeAlt size={16} className="text-guardian-darkblue" />
  //     </button>
  //   );
  // }

  const upArrow = (
    <button
      className="m-auto"
      onClick={() => {
        if (index === 1 && 'Escort Ad' == data.title) {
          console.log('index', index);
          console.log({ allSteps, data });
          // swapSteps(index, -1);
          swapAdStep(data);
        } else if (index >= 2) {
          swapSteps(index, -1);
        }
      }}
      type="button"
      disabled={
        !(index > 1 || (index === 1 && 'Escort Ad' == data.title)) ||
        status === CaseStatus.Submitted ||
        status === CaseStatus.Resubmitted
      }>
      <Icons.FaChevronUp
        size={16}
        className={
          index > 1 ||
          (index === 1 &&
            'Escort Ad' == data.title &&
            !(status === CaseStatus.Submitted || status === CaseStatus.Resubmitted))
            ? 'text-guardian-darkblue'
            : 'text-gray-200'
        }
      />
    </button>
  );
  // console.log({ status });
  const downArrow = (
    <button
      className="m-auto"
      onClick={() => {
        if (index === 0) {
          swapAdStep(data);
        } else if (index !== 0 && !isLast) {
          swapSteps(index, 1);
        }
      }}
      disabled={
        !(
          (index !== 0 && !isLast) ||
          (index === 0 && allSteps[index + 1] && allSteps[index + 1].title === 'Escort Ad')
        ) ||
        status === CaseStatus.Submitted ||
        status === CaseStatus.Resubmitted
      }
      title={
        (index !== 0 && !isLast) ||
        (index === 0 && allSteps && allSteps[index + 1] && allSteps[index + 1].title === 'Escort Ad')
          ? 'Swap Escort Ads'
          : 'Set Step 2 to an Escort Ad to change primary Ad'
      }
      type="button">
      {/* {index}
      {JSON.stringify(allSteps)} */}
      <Icons.FaChevronDown
        size={16}
        className={
          (index !== 0 && !isLast) ||
          (index === 0 &&
            allSteps[index + 1] &&
            allSteps[index + 1].title === 'Escort Ad' &&
            !(status === CaseStatus.Submitted || status === CaseStatus.Resubmitted))
            ? 'text-guardian-darkblue'
            : 'text-gray-200'
        }
      />
    </button>
  );

  const getAwardedPoints = awardData => {
    if (awardData.award === AwardTypes.VerficationSource) {
      if (awardClassificationOption === AwardClassificationOptions.Underage) {
        return 3;
      }

      return 1;
    }

    if (awardData.award === AwardTypes.AccountIdentified) {
      if (awardClassificationOption === AwardClassificationOptions.Underage) {
        return 5;
      }

      return 1;
    }

    return 0;
  };

  const getAwardColor = () => {
    if (!data?.award) {
      return 'bg-white text-pursuit-gray';
    }

    if (data.award === 'NO_POINTS') {
      return 'bg-pursuit-red text-white';
    }

    return 'bg-green-500 text-white';
  };

  function phoneData(arr) {
    return arr.map((obj, i) => (
      <div key={`Field${i}`}>
        <p className="pt-1 font-normal ">
          <b>Phone {i + 1}</b>
        </p>
        <div className="pb-1 pl-2 font-normal ">
          <p>
            <b>Number: </b>
            {obj.number}
          </p>
          <p>
            <b>Type: </b>
            {obj.type}
          </p>
        </div>
      </div>
    ));
  }

  function uniqueData(obj) {
    const { website } = data.body;
    const webSourceKey = website.toLowerCase().replace(/\s/g, '');
    const orderedFields = Object.keys(webSourceFields[webSourceKey]);
    const sortFunction = sortWebSourceFields(orderedFields);

    // return Object.entries(obj).map(([field, value], i) => {
    return Object.entries(obj)
      .sort(sortFunction)
      .map(([field, value], i) => {
        if (!value) {
          return <></>;
        }

        // const readableField = readableFormat(field);
        const readableField = webSourceFieldLabel(field, undefined, true);

        return (
          <p key={`Unique${data.id}${i}`} className="py-1 font-normal ">
            <b>{`${readableField}: `}</b>
            {fieldValue(field, value)}
          </p>
        );
      });
  }

  function defaultData(field, value, i) {
    const formatFieldName = x => {
      if (x.toLowerCase() === 'url') {
        return 'URL';
      }

      return x ? x.charAt(0).toUpperCase() + x.slice(1) : '';
    };

    if (field === 'description') {
      const { website = '' } = data.body;
      const webSourceKey = website.toLowerCase().replace(/\s/g, '');
      return (
        <p key={`Field${i}`} className="py-1 font-normal " style={{ overflowWrap: 'anywhere' }}>
          {/* <b className="text-pursuit-gray">{`${formatFieldName(field)}: `}</b> */}
          <b className="">{`${webSourceFieldLabel(field, webSourceKey, true)}: `}</b>
          <span
            dangerouslySetInnerHTML={{
              __html: parseMarkdown(value),
            }}
          />
          {/* {value} */}
        </p>
      );
    }

    return (
      <p key={`Field${i}`} className="py-1 font-normal " style={{ overflowWrap: 'anywhere' }}>
        <b className="">{`${formatFieldName(field)}: `}</b>
        {fieldValue(field, value)}
      </p>
    );
  }

  const expandArrow = expanded ? <Icons.FaChevronDown /> : <Icons.FaChevronRight />;
  const divHeight = expanded ? 'max-h-64r' : 'max-h-0';

  const renderStepContent = () => {
    if (!expanded) {
      return <div />;
    }
    // const fields = []
    // fields.push(...Object.entries(data.body));
    // const fields = [...Object.entries(data.body)];
    const fieldsString = JSON.stringify(Object.entries(data.body));
    const fields = JSON.parse(fieldsString);

    const idxOfDescription = fields.findIndex(x => Array.isArray(x) && x.length && x[0] === 'description');
    const { website = '' } = data.body;
    const webSourceKey = website.toLowerCase().replace(/\s/g, '');
    const orderedFields = Object.keys(webSourceFields[webSourceKey] || {});

    // ensure description is the last shown field
    if (idxOfDescription !== -1) {
      const description = fields[idxOfDescription];

      fields.splice(idxOfDescription, 1);

      fields.push(description);
    }

    const idxOfUrl = fields.findIndex(x => Array.isArray(x) && x.length && x[0] === 'url');
    if (idxOfUrl !== -1) {
      const url = fields[idxOfUrl];
      // console.log({ website });
      // if (orderedFields.includes('url')) {
      // This is such a hack
      if ('Escort Ad' === website) {
        const indexOfUniqueFields = fields.findIndex(x => Array.isArray(x) && x.length && x[0] === 'uniqueFields');
        const indexOfNewUrl = orderedFields.indexOf('url');
        // const indexOfUrl = Array.findIndex(fields, x => Array.isArray(x) && x.length && x[0] === 'url');
        // fields[indexOfUniqueFields][1].splice(indexOfNewUrl, 0, url);
        // Add URL to the unique fields
        fields[indexOfUniqueFields][1][url[0]] = url[1];
        // Remove URL from the fields
        fields.splice(idxOfUrl, 1);
      } else {
        const removed = fields.splice(idxOfUrl, 1);
        // console.log({fields, idxOfUrl, removed});
        // fields.push(url);
        fields.splice(fields.length - 1, 0, url);
      }
    }

    // console.log({ fields });

    return fields.map(([field, value], i) => {
      if (field === 'website' || field === 'id') return null;
      let content = value;
      content = defaultData(field, value, i);
      if (field === 'phones') {
        // return phoneData(value);
        content = phoneData(value);
      }

      if (field === 'uniqueFields') {
        // return uniqueData(value);
        content = uniqueData(value);
        // content = 'test';
      }

      // return defaultData(field, value, i);
      // console.log({ content, field, value });
      return <div key={`Field-${data.id}-${index}-${i}`}>{content}</div>;
    });
  };

  const renderFeedback = () => {
    if (!expanded) {
      return <></>;
    }

    return <Feedback comments={feedbackComments || []} stepId={data?.id} status={status} award={data?.award || null} />;
  };

  const stepContent = (
    <div key={`Data-${data.id}`} className={` pt-4 px-12 transition-maxheight ease-in-out duration-700 ${divHeight}`}>
      {renderStepContent()}
      {renderFeedback()}
    </div>
  );

  const editButton = (
    <button
      className="w-10 focus:outline-none"
      onClick={() => {
        editSource(data.id);
      }}
      type="button">
      <img src={EditIcon} alt="Edit" className="w-5" />
    </button>
  );

  const deleteButton =
    index === 0 ? (
      <div className="w-10" />
    ) : (
      <button
        className="w-10 focus:outline-none"
        onClick={() => {
          setShowDelete(true);
        }}
        type="button">
        <img src={TrashIcon} alt="Trash" className="w-5" />
      </button>
    );

  return (
    <div key={`Step${data.id}`}>
      <div className={`mb-4 p-2 pb-8 shadow font-bold transition-transform duration-500 transform ${translation}`}>
        <p className="m-2 mt-0 text-pursuit-darkred">Step {index + 1}</p>
        <div className="flex flex-row">
          {!noSwaps && (
            <div className="flex flex-col w-12">
              {/* {!noSwaps && swapAdButton} */}
              {/* {!noSwaps && index !== 0 && numSteps > 2 ? ( */}
              {/* {console.log({ noSwaps, numSteps, allSteps })} */}
              {!noSwaps && (numSteps > 2 || (numSteps > 1 && allSteps[0].title === 'Escort Ad')) ? (
                <>
                  {upArrow}
                  {downArrow}
                </>
              ) : null}
            </div>
          )}
          <div className="flex flex-row justify-between w-full p-2 mr-4 text-white bg-guardian-darkblue">
            <p>{getWebSourceSiteLabel(data.title)}</p>
            <div className="flex">
              {(status === CaseStatus.Approved || status === CaseStatus.Resubmitted) && (
                <div className={`${getAwardColor()} h-6 w-8 rounded-full flex justify-center items-center p-0 mr-4`}>
                  {getAwardedPoints(data, awardClassificationOption)}
                </div>
              )}
              <button onClick={toggleExpanded} type="button" className="focus:outline-none">
                {!hideExpand && expandArrow}
              </button>
            </div>
          </div>
          {editSource && !disableEdit && editButton}
          {deleteSource && !disableEdit && deleteButton}
        </div>
        {stepContent}
      </div>
      {showDelete ? (
        <PopUpConfirmation
          title="Delete Step"
          content={`Are you sure you want to delete ${data?.body?.website || 'this step'}?`}
          onConfirm={() => {
            deleteSource(data.id);
            setShowDelete(false);
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
          confirmText="DELETE"
          destructive
          className="w-11/12 lg:w-auto"
        />
      ) : null}
    </div>
  );
};

export default ConfirmationStep;
