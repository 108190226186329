import React, { useCallback, useEffect } from 'react';
import ReviewStep from '../ReviewStep';
import Dropdown from '../DropDown';
import { AwardClassificationOptions, CaseStatus, ESCORT_AD } from '../../constants';
import '@pathofdev/react-tag-input/build/index.css';
import PointBreakdown from '../PointBreakdown';
import VerticalSorter from '../VerticalSorter';
import TrashIcon from '../../assets/Images/trash.svg';
import { compileSteps } from './compileSteps';
import EditIcon from '../../assets/Images/edit.svg';
import PopUpConfirmation from '../PopupConfirmation';
import InvestigatorNotes from '../InvestigatorNotes';
import { v4 as uuidv4 } from 'uuid';
import { usesOldEscortAdFormat } from '../../helpers/caseForms/utilHelpers';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from '../SortableItem';
import Button from '../Button';
import * as Icons from 'react-icons/fa';

const awardClassificationOptions = [
  { label: 'Underage 18 <', value: AwardClassificationOptions.Underage },
  { label: 'Adult >', value: AwardClassificationOptions.Adult },
];

const ReviewForm = ({ caseState: state, setCaseState: setState, stepActions = null, editAction = id => {} }) => {
  const [compiledSteps, setCompiledSteps] = React.useState([]);
  const [caseState, setCaseState] = React.useState(state);
  const [showStaffDelete, setShowStaffDelete] = React.useState(null);
  const [showReorderSection, setShowReorderSection] = React.useState(false);
  const [expandAll, setExpandAll] = React.useState(false);

  // const [items, setItems] = React.useState([1, 2, 3]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = event => {
    const { active, over, ...rest } = event;
    console.log({ active, over, rest });
    if (active.id !== over.id) {
      // Don't do anything if the item is not a escort ad and is replacing a escort ad
      const overIndex = over.data.current.sortable.index;
      const activeIndex = active.data.current.sortable.index;
      const { items } = over.data.current.sortable;
      const secondItem = compiledSteps[1];
      const secondIsEscortAd = secondItem?.title === 'Escort Ad';
      const activeItem = compiledSteps.find(({ id }) => id === active.id);
      console.log({ overIndex, activeItem });
      if (activeItem.title !== 'Escort Ad' && overIndex === 0) {
        console.log('Can not replace the escort ad');
        return;
      }
      if (activeIndex === 0 && !secondIsEscortAd) {
        console.log('Can not move ad if second item is not ad');
        return;
      }
      // setCompiledSteps(items => {
      //   // console.log({ items, active, over });
      //   const oldIndex = items.findIndex(({ id }) => id === active.id);
      //   const newIndex = items.findIndex(({ id }) => id === over.id);
      //   const newItems = arrayMove(items, oldIndex, newIndex);
      //   const sourceOrder = newItems.map(({ id }) => id);
      //   console.log({ sourceOrder });
      //   // setCaseState({ ...caseState, sourceOrder });
      //   return newItems;
      // });
      // console.log({ items, active, over });
      const oldIndex = compiledSteps.findIndex(({ id }) => id === active.id);
      const newIndex = compiledSteps.findIndex(({ id }) => id === over.id);
      const newItems = arrayMove(compiledSteps, oldIndex, newIndex);
      const sourceOrder = newItems.map(({ id }) => id);
      const [firstSource, ...otherSources] = newItems;
      console.log({ newItems, sourceOrder, firstSource });
      const { uniqueFields = {} } = firstSource.body;
      const { adLocation = null } = uniqueFields;
      let { city, state } = caseState;
      if (adLocation) {
        [city, state] = adLocation.split(', ');
      }

      const newCaseState = JSON.parse(JSON.stringify({ ...caseState, city, state, sourceOrder }));
      // return Promise.all([setCompiledSteps(newItems), setCaseState(newCaseState)]);
      // setCompiledSteps(newItems);
      setCaseState(newCaseState);
      // setCompiledSteps(newItems);
      // return newItems;
    }
  };

  useEffect(() => {
    console.log('Case state changed', { caseState });
    setState(caseState);
  }, [caseState, setState]);

  const toggleExpandAll = () => setExpandAll(!expandAll);

  // const hideStaffDelete = () => {
  //   showStaffDelete(null);
  // };

  // const deleteSelectedStaffSource = useCallback(() => {
  //   console.log({ showStaffDelete });
  // }, [showStaffDelete]);

  const setFeedback = useCallback(
    ({ id, value }) => {
      const feedbackArray = caseState.feedbackComments ? [...caseState.feedbackComments] : [];

      const existingFeedbackIndex = feedbackArray.findIndex(x => x.stepId === id);

      if (existingFeedbackIndex === -1) {
        feedbackArray.push({
          stepId: id,
          feedback: value,
        });
      } else {
        const updatedFeedback = {
          ...feedbackArray[existingFeedbackIndex],
          feedback: value,
        };

        feedbackArray[existingFeedbackIndex] = updatedFeedback;
      }

      setCaseState({ ...caseState, feedbackComments: feedbackArray });
    },
    [caseState, setCaseState]
  );

  const setAwardOption = useCallback(
    ({ id, value }) => {
      const awardArray = caseState.awardedPoints ? [...caseState.awardedPoints] : [];

      const existingAwardIndex = awardArray.findIndex(x => x.stepId === id);

      if (!value) {
        if (existingAwardIndex !== -1) {
          awardArray.splice(existingAwardIndex, 1);
        }
        setCaseState({ ...caseState, awardedPoints: awardArray });
        return;
      }

      if (existingAwardIndex === -1) {
        awardArray.push({
          stepId: id,
          awardType: value,
        });
      } else {
        const updatedAwards = {
          ...awardArray[existingAwardIndex],
          awardType: value,
        };

        awardArray[existingAwardIndex] = updatedAwards;
      }

      setCaseState({ ...caseState, awardedPoints: awardArray });
    },
    [caseState, setCaseState]
  );

  const removeStaffSource = useCallback(
    id => {
      const { staffSources, awardedPoints } = caseState;
      const updatedStaffSources = staffSources.filter(s => s.id !== id);
      const updatedAwardedPoints = awardedPoints.filter(a => a.stepId !== id);

      setCaseState({
        ...caseState,
        staffSources: updatedStaffSources,
        awardedPoints: updatedAwardedPoints,
      });
    },
    [caseState, setCaseState]
  );
  const moveWebStep = useCallback(
    (index, direction, steps) => {
      const performSwap = (arr, idx, dir) => {
        const temp = arr[idx + dir];

        arr[idx + dir] = arr[idx];
        arr[idx] = temp;

        return arr;
      };

      const stepIdToMove = steps[index].id;
      const filteredSources = caseState.webSources.filter(Boolean);

      if (Array.isArray(filteredSources) && filteredSources.length > 0) {
        const webStepIndex = filteredSources.findIndex(({ id = null }) => id === stepIdToMove);
        // const webStepIndex = index - 1;

        // const updatedWebSources = performSwap([...caseState.webSources], index - 1, direction); // escort ad is idx 0, need to adjust
        const updatedWebSources = performSwap([...filteredSources], webStepIndex, direction); // escort ad is idx 0, need to adjust

        console.log({
          index,
          steps,
          stepIdToMove,
          webStepIndex,
          webSources: caseState.webSources,
          new: updatedWebSources,
        });
        // If not using the old escort ad format we need to update the city and state from the first web source
        let { city, state } = caseState;
        if (!usesOldEscortAdFormat(caseState)) {
          const [firstSource, ...otherSources] = updatedWebSources;
          const { uniqueFields } = firstSource;
          const { adLocation } = uniqueFields;
          if (adLocation) {
            [city, state] = adLocation.split(', ');
          }
        }

        setTimeout(() => {
          setCaseState({ ...caseState, city, state, webSources: updatedWebSources });
        }, 100);
      } else {
        console.log('No web sources');
      }
    },
    [caseState, setCaseState]
  );

  const moveStaffStep = useCallback(
    (index, direction, steps) => {
      console.log({ index, direction, steps });
      const directionStepId = steps[index + direction]?.id;
      const myId = steps[index]?.id;

      const { staffSources } = caseState;
      const staffSourceIndex = staffSources.findIndex(({ id }) => id === myId);

      console.log({
        staffSourceIndex,
        staffSources,
        directionStepId,
        index,
        direction,
        steps,
      });
      if (index + direction < 0) {
        // or can we?
        // Set the staff source previousSourceId to 0
        staffSources[staffSourceIndex].previousSourceId = '0';
        // Check if any steps are linked to this step and update their previousSourceId
        const linkedSteps = staffSources.filter(s => s.previousSourceId === myId);
        linkedSteps.forEach(s => {
          s.previousSourceId = myId;
        });
        // console.log({ linkedSteps, staffSources });
        console.log({ ...caseState, staffSources });
        setCaseState({ ...caseState, staffSources });
        // alert("Can't move staff step before the first volunteer step");
        return;
      }
      if (index + direction >= steps.length) {
        alert("Can't move staff out of range");
        return;
      }
      if (index === 1 && direction === -2) {
        alert("Can't replace first step");
        return;
      }

      if (staffSources[staffSourceIndex].id !== directionStepId) {
        staffSources[staffSourceIndex].previousSourceId = directionStepId;
        console.log({ staffSources });
        setCaseState({ ...caseState, staffSources });
      } else {
        console.warn("Can't move staff step to self");
      }
    },
    [caseState, setCaseState]
  );

  const moveStep = useCallback(
    (type, index, direction, steps) => {
      if (type === 'web') {
        // console.log({ type, index, direction, steps });
        // If moving down (+1) and the next step is a staff, we need to move that
        // staff step up (-1) so it can be anchored to a none staff step
        if (/* index + direction >= steps.length - 1 && */ steps[index + direction].previousSourceId) {
          // console.log('Custom move');
          // moveStep('staff', index, direction * -1, steps);
          moveStep('staff', index + direction, direction * -1, steps);
          // Last item is a web and move to a replace a staff. Need to move the staff event down
        } else {
          // console.log('Web', { index, direction });
          // If this web step has an attached staff step, move it down.
          const attached = steps.find(s => s.previousSourceId === steps[index].id);

          if (attached) {
            console.log('Need to move sub staff event');
          }

          // moveStep('staff', index + direction, direction * -1, steps);
          // }
          moveWebStep(index, direction, steps);
        }
      } else if (type === 'staff') {
        let move = direction;

        if (direction > 0) {
          // move++;
        } else {
          move--;
        }
        console.log('Staff', { index, move });
        moveStaffStep(index, move, steps);
      }
    },
    [moveStaffStep, moveWebStep]
  );

  // const swapAdStep = stepId => {
  //   const [firstSource, ...otherSources] = caseState.webSources;
  //   console.log({ stepId });
  //   console.log('firstSource', firstSource);
  //   if (stepId == undefined || stepId !== firstSource.id) {
  //     console.log('Attempting to swap the wrong step');
  //     return;
  //   }

  //   console.log({ caseState });
  //   if (firstSource === undefined || firstSource.website !== 'Escort Ad') return;

  //   const existingAd = caseState.escortAd;
  //   let { city, state } = caseState;

  //   // Convert the escort ad to a new source
  //   const { description, url, phones } = existingAd;
  //   const { number, type } = phones[0];
  //   const newSource = {
  //     // Create a new UUID
  //     id: uuidv4(),
  //     website: 'Escort Ad',
  //     url,
  //     description,
  //     uniqueFields: {
  //       phoneNumber: number,
  //       phoneType: type,
  //       adLocation: [city, state].filter(x => x).join(', '),
  //     },
  //   };
  //   const { uniqueFields, id, website, ...restOfFirstSource } = firstSource;
  //   const { adLocation, phoneNumber, phoneType } = uniqueFields;
  //   if (adLocation) {
  //     [city, state] = adLocation.split(', ');
  //   }

  //   // Convert the first source to the escort ad
  //   const newEscortAd = {
  //     ...restOfFirstSource,
  //     phones: [
  //       {
  //         id: 'initial',
  //         number: phoneNumber,
  //         type: phoneType,
  //       },
  //     ],
  //   };

  //   // Update the case state
  //   const newCaseState = {
  //     ...caseState,
  //     city,
  //     state,
  //     escortAd: newEscortAd,
  //     webSources: [newSource, ...otherSources],
  //   };
  //   console.log('newCaseState', newCaseState);
  //   setCaseState(newCaseState);
  // };
  // Copied from OrderStepForm
  const swapAdStep = () => {
    const [firstSource, secondSource, ...otherSources] = caseState.webSources;
    const existingAd = caseState.escortAd;
    // console.log({ caseState });
    const usesOldFormat = usesOldEscortAdFormat(caseState);
    console.log('firstSource', firstSource, caseState, usesOldFormat);
    if (firstSource === undefined || firstSource.website !== 'Escort Ad') return;

    let { city, state } = caseState;

    if (!usesOldFormat) {
      const { uniqueFields } = secondSource;
      const { adLocation } = uniqueFields;
      if (adLocation) {
        [city, state] = adLocation.split(', ');
      }
      const updatedCase = { ...caseState, city, state, webSources: [secondSource, firstSource, ...otherSources] };
      console.log({ updatedCase, uniqueFields, city, state });
      setCaseState(updatedCase);
      // swapSteps(0, 1);
      return;
    }

    const { uniqueFields, id, website, ...restOfFirstSource } = firstSource;
    const { adLocation, phoneNumber, phoneType } = uniqueFields;
    if (adLocation) {
      [city, state] = adLocation.split(', ');
    }
    // Convert the escort ad to a new source
    const { description, url, phones } = existingAd;
    const { number, type } = phones[0];
    const newSource = {
      // Create a new UUID
      id: uuidv4(),
      website: 'Escort Ad',
      url,
      description,
      uniqueFields: {
        phoneNumber: number,
        phoneType: type,
        adLocation: [city, state].filter(x => x).join(', '),
      },
    };

    // Convert the first source to the escort ad
    const newEscortAd = {
      ...restOfFirstSource,
      phones: [
        {
          id: 'initial',
          number: phoneNumber,
          type: phoneType,
        },
      ],
    };

    // Update the case state
    const newCaseState = {
      ...caseState,
      city,
      state,
      escortAd: newEscortAd,
      webSources: [newSource, secondSource, ...otherSources],
    };
    console.log('newCaseState', newCaseState);
    setCaseState(newCaseState);
  };

  useEffect(() => {
    const awards = caseState.awardedPoints ? [...caseState.awardedPoints] : [];
    const feedback = caseState.feedbackComments ? [...caseState.feedbackComments] : [];
    const steps = [];
    // const steps = [
    //   {
    //     title: 'Escort Ad',
    //     body: caseState.escortAd,
    //     id: ESCORT_AD,
    //     feedback: feedback.find(x => x.stepId === ESCORT_AD)?.feedback || '',
    //     award: awards.find(x => x.stepId === ESCORT_AD)?.awardType || null,
    //   },
    // ];
    const escortAdData = [
      caseState.escortAd.url,
      ...caseState.escortAd.phones.map(({ phone }) => phone),
      caseState.escortAd.description,
    ];
    const escortAdEmpty = escortAdData.filter(Boolean).length === 0;
    if (!escortAdEmpty) {
      // steps.push(escortAdData);
      steps.push({
        title: 'Escort Ad',
        body: caseState.escortAd,
        id: ESCORT_AD,
        feedback: feedback.find(x => x.stepId === ESCORT_AD)?.feedback || '',
        award: awards.find(x => x.stepId === ESCORT_AD)?.awardType || null,
      });
    }

    const webSources = caseState.webSources.map(source => ({
      title: source.website ? source.website : '[no source type selected]',
      body: source,
      id: source.id,
      feedback: feedback.find(x => x.stepId === source.id)?.feedback || '',
      award: awards.find(x => x.stepId === source.id)?.awardType || null,
    }));
    const staffSources = caseState.staffSources
      ? caseState.staffSources.map(ss => {
          const { author, previousSourceId, ...commonFields } = ss;

          return {
            title: ss.website ? ss.website : '[no source type selected]',
            body: commonFields,
            previousSourceId,
            author,
            id: ss.id,
            feedback: feedback.find(x => x.stepId === ss.id)?.feedback || '',
            award: awards.find(x => x.stepId === ss.id)?.awardType || null,
          };
        })
      : [];
    const cs = compileSteps(steps, webSources, staffSources, caseState.sourceOrder);

    // console.log({ cs });
    setCompiledSteps(cs);
  }, [caseState]);

  // const reorderer = useCallback(() => {
  //   if (!showReorderSection) {
  //     return;
  //   }
  //   const reorderSection = (
  //     <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
  //       <SortableContext items={compiledSteps} strategy={verticalListSortingStrategy}>
  //         {/* {items.map(id => (
  //           <SortableItem key={id} id={id} children={id} />
  //         ))} */}
  //         {compiledSteps.map((data, idx, arr) => (
  //           <SortableItem key={data.id} id={data.id} children={`${data.id} ${data.title} - ${data.body.url}`} />
  //         ))}
  //       </SortableContext>
  //     </DndContext>
  //   );
  //   return reorderSection;
  // }, [compiledSteps, showReorderSection]);

  const reOrderButton = (
    <Button
      solidBlue
      padding="px-4 py-1"
      className="my-2 mr-8"
      onClick={() => setShowReorderSection(value => !value)} /* disabled={disableEdit} */
    >
      <span className="p-0 m-0 nowrap" style={{ whiteSpace: 'nowrap' }}>
        Reorder
      </span>
    </Button>
  );

  const renderSteps = useCallback(() => {
    // const reorderSection = (
    //   <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
    //   <SortableContext items={compiledSteps} strategy={verticalListSortingStrategy}>
    //     {/* {items.map(id => (
    //       <SortableItem key={id} id={id} children={id} />
    //     ))} */}
    //     {compiledSteps.map((data, idx, arr) => (
    //       <SortableItem key={data.id} id={data.id} children={`${data.id} ${data.title} - ${data.body.url}`} />
    //     ))}
    //     </SortableContext>
    //   </DndContext>
    // )

    // <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
    //   <SortableContext items={compiledSteps} strategy={verticalListSortingStrategy}>
    //     {compiledSteps.map((data, idx, arr) => (
    //       <SortableItem key={data.id} id={data.id} children={`${data.id} ${data.title} - ${data.body.url}`} />
    //     ))}
    return compiledSteps.map((data, idx, arr) => {
      // // Allow swapping of step 2 and escort add if step is a escort ad type
      // const allowEscortSwap = idx == 1 && data.id === ESCORT_AD;

      // console.log({data, idx, arr})
      const sorter = (
        <VerticalSorter
          up={
            (arr.length > 2 && idx > 1) || // If there are more than 2 steps and this is not the first step allow moving up
            (usesOldEscortAdFormat(caseState) && idx === 1 && 'Escort Ad' == data.title) || // If this is the second step and the first step is an escort ad allow moving up
            (!usesOldEscortAdFormat(caseState) &&
              arr[idx - 1]?.title === 'Escort Ad' &&
              // !(idx === 1 && data.author) && // If this is the second step and not a staff source
              true) // this is here to allow quick commenting out of the above line
          }
          // up={arr.length > 2 || (idx === 1 && 'Escort Ad' == data.title)}
          down={
            (idx > 0 && arr.length > 2 && idx < arr.length - 1) ||
            (!usesOldEscortAdFormat(caseState) &&
              idx === 0 &&
              arr[idx + 1]?.title === 'Escort Ad' &&
              // !(idx === 0 && arr[1].author) &&  // If this is the first step and not a staff source
              true) // this is here to allow quick commenting out of the above line
          }
          onUp={() => {
            if (idx === 1 && 'Escort Ad' == data.title) {
              console.log('Swap ad');
              if (usesOldEscortAdFormat(caseState)) {
                swapAdStep(data.id);
              } else {
                return moveStep(data?.previousSourceId ? 'staff' : 'web', idx, -1, arr);
              }
              return;
            }
            return moveStep(data?.previousSourceId ? 'staff' : 'web', idx, -1, arr);
          }}
          onDown={() => moveStep(data?.previousSourceId ? 'staff' : 'web', idx, 1, arr)}
          // onDown={() => moveStep(idx, 1)}
        />
      );
      const actions = (
        <div className="flex flex-row">
          <button
            className="w-10 focus:outline-none"
            onClick={() => {
              editAction(idx);
            }}
            type="button">
            <img src={EditIcon} alt="Edit" className="w-5" />
          </button>
          {(data.author && (
            <button
              className="w-10 focus:outline-none"
              onClick={() =>
                // console.log({ data });

                setShowStaffDelete(data.id)
              }
              type="button">
              <img src={TrashIcon} alt="Trash" className="w-5" />
            </button>
          )) ||
            null}
        </div>
      );

      return (
        <div key={idx} className="flex flex-row">
          {showStaffDelete && showStaffDelete === data.id && (
            <PopUpConfirmation
              title="Delete Step"
              content={`Are you sure you want to delete ${data?.body?.website || 'this step'}?`}
              onConfirm={() => {
                // deleteSource(index);
                removeStaffSource(data.id);
                setShowStaffDelete(null);
              }}
              onCancel={() => {
                // setShowDelete(false);
                setShowStaffDelete(null);
              }}
              confirmText="DELETE"
              destructive
              className="w-11/12 lg:w-auto"
            />
          )}
          <div className="w-full">
            <ReviewStep
              key={`Source_${data.id}`}
              data={data}
              index={idx}
              numSteps={arr.length}
              isLast={idx === arr.length - 1}
              setFeedback={setFeedback}
              setAwardOption={setAwardOption}
              awardClassificationOption={caseState.awardClassificationOption}
              status={caseState?.status}
              // forceExpanded
              noSwaps
              expandAll={expandAll}
              // customSorter={sorter}
              actions={actions}
            />
          </div>
        </div>
      );
    });
    //   </SortableContext>
    // </DndContext>
  }, [
    compiledSteps,
    setFeedback,
    setAwardOption,
    caseState.awardClassificationOption,
    caseState.status,
    moveStep,
    removeStaffSource,
    showStaffDelete,
    expandAll,
    // items,
  ]);

  const stepsContent = renderSteps();
  const investigatorNotes = (
    // <p>
    //   <b className="text-pursuit-gray">Investigator Notes: </b>
    //   {caseState.investigatorNotes || 'N/A'}
    // </p>
    <>
      <div className="flex flex-col mt-8">
        {/* <label className="mb-2 text-lg font-bold text-pursuit-gray">Investigator Notes</label>

        <textarea
          // disabled
          placeholder="No notes provided"
          className="h-24 pl-1 mt-1 mb-2 shadow"
          value={caseState.investigatorNotes}
          onChange={event => {
            // console.log(event.target.value);

            setCaseState({
              ...caseState,
              investigatorNotes: event.target.value,
            });
          }}
        /> */}
        <InvestigatorNotes
          notes={caseState.investigatorNotes}
          visibility={['employee']}
          newVisibility="employee"
          setNotes={note => {
            setCaseState({
              ...caseState,
              investigatorNotes: note,
            });
          }}
        />
        {/* <button onClick={save()}>Save</button> */}
      </div>
    </>
  );

  const personalDetailsBlock = (
    <div className="py-4 mb-4">
      <div>
        <h2 className="mb-2 text-lg font-bold text-pursuit-gray">Personal Details</h2>
        <ul>
          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Name: </b>
              {`${caseState.firstName || ''} ${caseState.middleName || ''} ${caseState.lastName || ''}`}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Age: </b>
              {caseState.age}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Location: </b>
              {`${caseState.city || ''}, ${caseState.state || ''}`}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Comments: </b>
              {caseState.details}
            </p>
          </li>

          {/* <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Investigator Notes: </b>
              {caseState.investigatorNotes}
            </p>
          </li> */}
        </ul>

        <div className="w-full mt-4 lg:w-64">
          <label className="font-bold text-pursuit-gray">
            Award Classification Option <span className="text-pursuit-red">*</span>
          </label>
          <Dropdown
            value={awardClassificationOptions.find(x => x.value === caseState.awardClassificationOption) || null}
            onChange={option =>
              setCaseState({
                ...caseState,
                awardClassificationOption: option.value,
              })
            }
            width="w-full"
            options={awardClassificationOptions}
            disabled={caseState?.status === CaseStatus.Approved || caseState?.status === CaseStatus.Resubmitted}
            required
          />
        </div>

        {(caseState.status === CaseStatus.Approved || caseState.status === CaseStatus.Resubmitted) && (
          <div className="mt-8 mb-8">
            <PointBreakdown
              expandAll
              noSwaps
              feedbackComments={caseState?.feedbackComments || []}
              status={caseState?.status}
              awardClassificationOption={caseState?.awardClassificationOption}
              caseRecord={caseState}
            />
          </div>
        )}
      </div>
    </div>
  );

  const header = (
    <div className="mb-8">
      <h2 className="text-lg font-bold text-pursuit-gray">Case Review</h2>
      <div className="text-sm text-pursuit-gray">
        <p>
          Take a moment to validate the sources provided by the volunteer that submitted this investigation. Please be
          sure to provide feedback for anything that looks incorrect so the volunteer can make appropriate updates after
          the case is returned. Your feedback will not be visible to the volunteer until the case is returned. Once you
          have validated that everything is correct, please award points accordingly and approve and share the case
          information.
        </p>
      </div>
    </div>
  );

  const tabHeader = (
    <>
      {header}
      {personalDetailsBlock}
    </>
  );

  const onDragOver = event => {
    console.log('Drag over', { event });
  };

  return (
    <>
      <>
        {tabHeader}
        {/* {stepActions && <div className="flex flex-col-reverse lg:flex-row-reverse">{stepActions}</div>} */}
        <div className="flex flex-col-reverse lg:flex-row-reverse">
          {caseState.webSources.length <= 0 ? (
            <></>
          ) : (
            <>
              <Button solidBlue className="w-32 my-2" onClick={toggleExpandAll}>
                {expandAll ? <>Collapse All</> : <>Expand All</>}
              </Button>
            </>
          )}
          {reOrderButton}
          {stepActions}
        </div>
        {/* {reorderer()} */}
        {showReorderSection && (
          <PopUpConfirmation
            // noCancel={sendMessageOnApproval}
            noCancel={true}
            // noConfirm={sendMessageOnApproval}
            title="Reorder Steps"
            contentWrapper="p-3 bg-gray-100 border border-black rounded"
            // content="Are you sure you are ready to approve this case and award points to the submitter?"
            content={
              <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <div
                  className="overflow-y-auto overflow-x-visible"
                  style={{
                    maxHeight: '40vh',
                  }}>
                  <SortableContext
                    items={compiledSteps}
                    strategy={verticalListSortingStrategy}
                    // disabled={{
                    //   droppable: true,
                    // }}
                  >
                    {compiledSteps.map((data, idx, arr) => (
                      <SortableItem
                        key={data.id}
                        id={data.id}
                        data={data}
                        items={arr}
                        disabled={true}
                        onDragOver={onDragOver}
                        children={
                          <div className="flex items-center" title="Click and drag to reorder">
                            <span>
                              <Icons.FaGripVertical className="mr-2 text-gray-400" />
                            </span>
                            <span className="text-pursuit-darkred font-bold">Step {idx + 1}:&nbsp;</span>
                            <span>{data.title}</span>
                            <span className="text-pursuit-gray">&nbsp;{data.body.url && `- ${data.body.url}`}</span>
                          </div>
                        }
                      />
                    ))}
                  </SortableContext>
                </div>
              </DndContext>
            }
            // onCancel={() => setApproveReady(false)}
            onConfirm={() => setShowReorderSection(false)}
            confirmText="Close"
            // confirmLoading={approving}
            // confirmLoadingText="APPROVING"
            className="w-11/12 lg:w-auto lg:w-1/2"
          />
        )}

        {stepsContent}
        {investigatorNotes}
        {/* <code>{JSON.stringify(caseState)}</code> */}
        {/* <pre>
        <code>{JSON.stringify(compiledSteps, null, 2)}</code>
        </pre> */}
      </>
    </>
  );
};

export default ReviewForm;
