import React, { useState, useEffect } from 'react';
import ConfirmationStep from '../ConfirmationStep';
import Button from '../Button';
import { ESCORT_AD, CaseStatus, AwardTypes } from '../../constants';
import PointBreakdown from '../PointBreakdown';
import InvestigatorNotes from '../InvestigatorNotes';
import { v4 as uuidv4 } from 'uuid';
import { usesOldEscortAdFormat } from '../../helpers/caseForms/utilHelpers';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from '../SortableItem';
import PopUpConfirmation from '../PopupConfirmation';
import { compileSteps } from '../ReviewForm/compileSteps';
import * as Icons from 'react-icons/fa';

const OrderStepsForm = ({ caseState, setCaseState, editSource, addButton, disableEdit, save }) => {
  const [expandAll, setExpandAll] = useState(false);
  const [awardClassificationOption, setAwardClassificationOption] = useState('');
  const [usesOldFormat, setUsesOldFormat] = useState(false);
  const [compiledSteps, setCompiledSteps] = useState([]);

  const [showReorderSection, setShowReorderSection] = React.useState(false);

  // const [items, setItems] = React.useState([1, 2, 3]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = event => {
    const { active, over, ...rest } = event;
    console.log({ active, over, rest });
    if (active.id !== over.id) {
      // Don't do anything if the item is not a escort ad and is replacing a escort ad
      const overIndex = over.data.current.sortable.index;
      const activeIndex = active.data.current.sortable.index;
      const { items } = over.data.current.sortable;
      const secondItem = compiledSteps[1];
      const secondIsEscortAd = secondItem?.title === 'Escort Ad' || secondItem?.website === 'Escort Ad';
      const activeItem = compiledSteps.find(({ id }) => id === active.id);
      console.log({ overIndex, activeItem });
      if (!(activeItem.title === 'Escort Ad' || activeItem.website === 'Escort Ad') && overIndex === 0) {
        console.log('Can not replace the escort ad');
        return;
      }
      if (activeIndex === 0 && !secondIsEscortAd) {
        console.log('Can not move ad if second item is not ad');
        return;
      }
      const defaultOrder = compiledSteps.map(({ id }) => id);
      const oldIndex = (caseState.sourceOrder || defaultOrder).findIndex(id => id === active.id);
      const newIndex = (caseState.sourceOrder || defaultOrder).findIndex(id => id === over.id);
      // const newItems = arrayMove(compiledSteps, oldIndex, newIndex);
      const sourceOrder = arrayMove(caseState.sourceOrder || defaultOrder, oldIndex, newIndex);
      // const sourceOrder = arrayMove(caseState.sourceOrder || compiledSteps, oldIndex, newIndex);
      // const sourceSet = new Set(caseState.sourceOrder);
      // const sourceOrder  = new Set(newItems.map(({ id }) => id));
      console.log('test', JSON.stringify({ oldIndex, newIndex, sourceOrder }));
      const newSortedCompiledSteps = compiledSteps.sort(
        (a, b) => sourceOrder.indexOf(a.id) - sourceOrder.indexOf(b.id)
      );
      // newItems.forEach(({ id }) => sourceSet.add(id));
      // const sourceOrder = newItems.map(({ id }) => id);
      // const sourceOrder = Array.from(sourceSet);
      const [firstSource, ...otherSources] = newSortedCompiledSteps;
      console.log({ oldSort: caseState.sourceOrder, sourceOrder, firstSource, newSortedCompiledSteps });
      const { uniqueFields = {} } = firstSource;
      const { adLocation = null } = uniqueFields;
      let { city, state } = caseState;
      if (adLocation) {
        [city, state] = adLocation.split(', ');
      }

      const newCaseState = JSON.parse(JSON.stringify({ ...caseState, city, state, sourceOrder }));
      setCaseState(newCaseState);
    }
  };

  const reOrderButton = (
    <Button
      solidBlue
      padding="px-4 py-1"
      className="my-2 mr-8"
      onClick={() => setShowReorderSection(value => !value)} /* disabled={disableEdit} */
    >
      <span className="p-0 m-0 nowrap" style={{ whiteSpace: 'nowrap' }}>
        Reorder
      </span>
    </Button>
  );

  useEffect(() => {
    // const usesOldFormat = usesOldEscortAdFormat(caseState);
    setUsesOldFormat(usesOldEscortAdFormat(caseState));

    // Update compiled steps
    // const steps = compileSteps(caseState.steps, caseState.webSources, caseState.sourceOrder);
    // const steps = compileSteps(caseState.steps, caseState.webSources, caseState.sourceOrder);
    // const steps = compileSteps([], caseState.webSources || [], caseState.staffSources || [], caseState.sourceOrder);
    const steps = compileSteps([], caseState.webSources || [], [], caseState.sourceOrder);
    // console.log({ steps });
    setCompiledSteps(steps);
  }, [caseState]);

  useEffect(() => {
    setAwardClassificationOption(caseState.awardClassificationOption);
  }, [caseState.awardClassificationOption]);

  function setInput(key, value) {
    setCaseState({ ...caseState, [key]: value });
  }

  const toggleExpandAll = () => setExpandAll(!expandAll);

  const swapSteps = (index, direction) => {
    const performSwap = (arr, idx, dir) => {
      const temp = arr[idx + dir];

      arr[idx + dir] = arr[idx];
      arr[idx] = temp;

      return arr;
    };
    // If using the old format adjust the index by 1 to offset it
    if (!usesOldEscortAdFormat(caseState)) {
      index += 1;
    }

    const updatedWebSources = performSwap([...caseState.webSources], index - 1, direction); // escort ad is idx 0, need to adjust

    setTimeout(() => {
      setCaseState({ ...caseState, webSources: updatedWebSources });
    }, 100);
  };

  // Swap the escort ad with the first source.
  const swapAdStep = () => {
    const [firstSource, secondSource, ...otherSources] = caseState.webSources;
    const existingAd = caseState.escortAd;
    // console.log({ caseState });
    // const usesOldFormat = usesOldEscortAdFormat(caseState);
    console.log('firstSource', firstSource, caseState, usesOldFormat);
    if (firstSource === undefined || firstSource.website !== 'Escort Ad') return;

    let { city, state } = caseState;

    if (!usesOldFormat) {
      const { uniqueFields } = secondSource;
      const { adLocation } = uniqueFields;
      if (adLocation) {
        [city, state] = adLocation.split(', ');
      }
      const updatedCase = { ...caseState, city, state, webSources: [secondSource, firstSource, ...otherSources] };
      console.log({ updatedCase, uniqueFields, city, state });
      setCaseState(updatedCase);
      // swapSteps(0, 1);
      return;
    }

    const { uniqueFields, id, website, ...restOfFirstSource } = firstSource;
    const { adLocation, phoneNumber, phoneType } = uniqueFields;
    if (adLocation) {
      [city, state] = adLocation.split(', ');
    }
    // Convert the escort ad to a new source
    const { description, url, phones } = existingAd;
    const { number, type } = phones[0];
    const newSource = {
      // Create a new UUID
      id: uuidv4(),
      website: 'Escort Ad',
      url,
      description,
      uniqueFields: {
        phoneNumber: number,
        phoneType: type,
        adLocation: [city, state].filter(x => x).join(', '),
      },
    };

    // Convert the first source to the escort ad
    const newEscortAd = {
      ...restOfFirstSource,
      phones: [
        {
          id: 'initial',
          number: phoneNumber,
          type: phoneType,
        },
      ],
    };

    // Update the case state
    const newCaseState = {
      ...caseState,
      city,
      state,
      escortAd: newEscortAd,
      webSources: [newSource, secondSource, ...otherSources],
    };
    console.log('newCaseState', newCaseState);
    setCaseState(newCaseState);
  };

  const deleteSource = id => {
    const sources = [...caseState.webSources];
    const staffSources = caseState.staffSources ? [...caseState.staffSources] : [];
    let spliceOffset = sources.findIndex(x => x.id === id);
    if (usesOldEscortAdFormat(caseState)) {
      spliceOffset -= 1;
    }

    console.log({ spliceOffset, id, usesOldEscortAdFormat: usesOldEscortAdFormat(caseState) });

    sources.splice(spliceOffset, 1);
    const webSourceIds = sources.map(x => x.id);
    const staffSourceIds = staffSources.map(x => x.id);
    const newAwardedPoints = (caseState.awardedPoints || []).filter(
      x => x.stepId === 'ESCORT_AD' || webSourceIds.includes(x.stepId) || staffSourceIds.includes(x.stepId)
    );
    const newCaseState = { ...caseState, webSources: [...sources], awardedPoints: [...newAwardedPoints] };

    // setCaseState({ ...caseState, webSources: [...sources] });
    // console.log({ newCaseState, newAwardedPoints });
    setCaseState(newCaseState);
  };
  const setNotes = notes => {
    // console.log('setNotes', note);
    // const data = { ...caseState, investigatorNotes: notes };

    // console.log('setNotes', data);
    // setInput('investigatorNotes', notes);
    setCaseState({ ...caseState, investigatorNotes: notes });
    // save({data});
  };

  const renderSteps = () => {
    const awards = caseState.awardedPoints ? [...caseState.awardedPoints] : [];

    const steps = [
      // {
      //   title: 'Escort Ad',
      //   body: caseState.escortAd,
      //   id: ESCORT_AD,
      //   award: awards.find(x => x.stepId === ESCORT_AD)?.awardType || null,
      // },
    ];
    // Check if any of the old escort ad fields are filled
    const escortAdData = [
      caseState.escortAd.url,
      ...caseState.escortAd.phones.map(({ phone }) => phone),
      caseState.escortAd.description,
    ];
    const escortAdEmpty = escortAdData.filter(Boolean).length === 0;

    if (!escortAdEmpty) {
      steps.push({
        title: 'Escort Ad',
        body: caseState.escortAd,
        id: ESCORT_AD,
        award: awards.find(x => x.stepId === ESCORT_AD)?.awardType || null,
      });
    }
    const primaryEscortAdOverride =
      caseState?.staffSources &&
      caseState.staffSources.find(x => x.website === 'Escort Ad' && x.previousSourceId === '0');
    if (primaryEscortAdOverride) {
      steps.push({
        title: primaryEscortAdOverride.website,
        body: primaryEscortAdOverride,
        id: primaryEscortAdOverride.id,
      });
    }

    caseState.webSources.forEach(source => {
      steps.push({
        title: source.website ? source.website : '[no source type selected]',
        body: source,
        id: source.id,
        award: awards.find(x => x.stepId === source.id)?.awardType || null,
      });
    });

    const getIsApproved = idx => {
      let isApproved = false;
      const indexOffset = usesOldFormat || primaryEscortAdOverride ? -1 : 0;

      if (idx > 0) {
        const currStepAward =
          caseState?.awardedPoints?.find(source => source.stepId === caseState.webSources[idx + indexOffset]?.id) ||
          null;

        if (
          caseState.status === CaseStatus.Approved &&
          (currStepAward?.awardType === AwardTypes.AccountIdentified ||
            currStepAward?.awardType === AwardTypes.VerficationSource)
        ) {
          isApproved = true;
        }
      } else if (caseState.status === CaseStatus.Approved) {
        isApproved = true;
      }

      return isApproved;
    };

    const filteredSteps = compiledSteps.map(data => {
      const award = awards.find(x => x.stepId === data.id)?.awardType || null;
      return {
        title: data.website,
        id: data.id,
        body: data,
        award,
        ...data,
      };
    });
    // return steps.map((data, idx, allSteps) => (
    return filteredSteps.map((data, idx, allSteps) => (
      <ConfirmationStep
        noSwaps
        key={`Source_${data.id}`}
        data={data}
        index={idx}
        numSteps={allSteps.length}
        isLast={idx === allSteps.length - 1}
        swapSteps={swapSteps}
        editSource={editSource}
        deleteSource={deleteSource}
        expandAll={expandAll}
        feedbackComments={caseState?.feedbackComments || []}
        status={caseState?.status}
        awardClassificationOption={awardClassificationOption}
        disableEdit={disableEdit || getIsApproved(idx)}
        allSteps={allSteps}
        swapAdStep={swapAdStep}
        // swapAdStep={stepToReplaceEscortAd => {

        //   console.log('stepToReplaceEscortAd', stepToReplaceEscortAd);
        // }}
      />
    ));
  };

  return (
    <>
      <div className="mb-8">
        {caseState.status === CaseStatus.Approved || caseState.status === CaseStatus.Resubmitted ? (
          <>
            <h2 className="text-lg font-bold text-pursuit-gray">Review Approved Case</h2>
            <div className="text-sm text-pursuit-gray">
              <p>
                Please review all the steps you took to create this investigation. Use the reorder button below to
                rearrange the steps in the order of your discovery process. Edit and delete sources that weren't awarded
                points and add additional sources. Approved sources cannot be edited.
              </p>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-lg font-bold text-pursuit-gray">Review and Adjust</h2>
            <div className="text-sm text-pursuit-gray">
              <p>
                Please review all the steps you took to create this investigation. Use the reorder button below to
                rearrange the steps in the order of your discovery process.
              </p>
            </div>
          </>
        )}
      </div>
      <div className="py-4 mb-4">
        <h2 className="mb-2 text-lg font-bold text-pursuit-gray">Personal Details</h2>
        <ul>
          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Name: </b>
              {`${caseState.firstName || ''} ${caseState.middleName || ''} ${caseState.lastName || ''}`}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Age: </b>
              {caseState.age}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Location: </b>
              {`${caseState.city || ''}, ${caseState.state || ''}`}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Comments: </b>
              {caseState.details}
            </p>
          </li>
        </ul>
      </div>

      {(caseState.status === CaseStatus.Approved || caseState.status === CaseStatus.Resubmitted) && (
        <div className="mb-8">
          <PointBreakdown
            expandAll
            noSwaps
            feedbackComments={caseState?.feedbackComments || []}
            status={caseState?.status}
            awardClassificationOption={awardClassificationOption}
            caseRecord={caseState}
          />
        </div>
      )}
      <div className="flex float-right">
        {addButton}
        {reOrderButton}
        {caseState.webSources.length <= 0 ? (
          <></>
        ) : (
          <>
            <Button solidBlue className="w-32 my-2" onClick={toggleExpandAll}>
              {expandAll ? <>Collapse All</> : <>Expand All</>}
            </Button>
          </>
        )}
      </div>
      {showReorderSection && (
        <PopUpConfirmation
          // noCancel={sendMessageOnApproval}
          noCancel={true}
          // noConfirm={sendMessageOnApproval}
          title="Reorder Steps"
          contentWrapper="p-3 bg-gray-100 border border-black rounded"
          // content="Are you sure you are ready to approve this case and award points to the submitter?"
          content={
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <div
                className="overflow-y-auto overflow-x-visible"
                style={{
                  maxHeight: '40vh',
                }}>
                <SortableContext
                  items={compiledSteps}
                  strategy={verticalListSortingStrategy}
                  // disabled={{
                  //   droppable: true,
                  // }}
                >
                  {compiledSteps.map((data, idx, arr) => (
                    <SortableItem
                      key={data.id}
                      id={data.id}
                      data={data}
                      items={arr}
                      disabled={true}
                      // onDragOver={onDragOver}
                      children={
                        <div className="flex items-center" title="Click and drag to reorder">
                          <span>
                            <Icons.FaGripVertical className="mr-2 text-gray-400" />
                          </span>
                          <span className="text-pursuit-darkred font-bold">Step {idx + 1}:&nbsp;</span>
                          <span>{data.website}</span>
                          <span className="text-pursuit-gray">&nbsp;{data?.url && `- ${data?.url}`}</span>
                        </div>
                      }
                    />
                  ))}
                </SortableContext>
              </div>
            </DndContext>
          }
          // onCancel={() => setApproveReady(false)}
          onConfirm={() => setShowReorderSection(false)}
          confirmText="Close"
          // confirmLoading={approving}
          // confirmLoadingText="APPROVING"
          className="w-11/12 lg:w-auto lg:w-1/2"
        />
      )}

      <div className="clear-both" />
      {renderSteps()}

      <div className="flex flex-col mt-8">
        {/* <label className="mb-2 text-lg font-bold text-pursuit-gray">Investigator Notes</label> */}

        {/* <textarea
          disabled={disableEdit}
          placeholder="Notes or comments"
          className="h-24 pl-1 mt-1 mb-2 shadow"
          value={caseState.investigatorNotes}
          onChange={event => setInput('investigatorNotes', event.target.value)}
        /> */}

        <InvestigatorNotes
          canEdit={!disableEdit}
          canSort={!disableEdit}
          canDelete={!disableEdit}
          notes={caseState.investigatorNotes}
          // newVisibility={null}
          setNotes={setNotes}
        />
      </div>
    </>
  );
};

export default OrderStepsForm;
