import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const SortableItem = ({ children = null, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    disabled,
    over,
    overIndex,
    index,
    newIndex,
  } = useSortable({
    id: props.id,
  });
  const classNames = 'rounded shadow-lg p-2 my-2 bg-white truncate';
  const { website, title } = props.data;
  const { items } = props;
  let wrapperClass = '';
  let cantDrop = false;
  // Confirm that item is a escort ad if in index 0

  // const cantBeFirstSource = isDragging && overIndex === 0 && 'Escort Ad' !== title;
  // const secondSourceIsEscortAd = items[1] && 'Escort Ad' === items[1].title;
  // const cantBeRemovedAsFirst = !cantBeFirstSource && !secondSourceIsEscortAd;
  // const cantDrop = cantBeFirstSource || cantBeRemovedAsFirst;
  const isFirstPosition = index === 0;
  const isEscortAd = website === 'Escort Ad' || title === 'Escort Ad';
  const isSecondEscortAd =
    (items[1] && items[1].website === 'Escort Ad') || (items[1] && items[1].title === 'Escort Ad');
  if (isDragging && isFirstPosition && !isSecondEscortAd) {
    cantDrop = true;
  }
  if (isDragging && newIndex === 0 && !isEscortAd) {
    console.log('isDragging', { isDragging, newIndex, isEscortAd, props });
    cantDrop = true;
  }
  // const isMovingOutOfFirstPosition = overIndex !== 0 && isFirstPosition;
  // const isMovingIntoFirstPosition = overIndex === 0 && !isFirstPosition;
  // const secondSourceIsEscortAd = items[1] && items[1].title === 'Escort Ad';

  // const cantDrop =
  //   (isFirstPosition && !secondSourceIsEscortAd) ||
  //   (isMovingOutOfFirstPosition && !secondSourceIsEscortAd) ||
  //   (isMovingIntoFirstPosition && !cantBeFirstSource);

  // console.log('overIndex', {
  //   over,
  //   overIndex,
  //   props,
  //   index,
  //   newIndex,
  //   // secondSourceIsEscortAd,
  //   cantDrop,
  //   items,
  //   second: items[1],
  // });
  if (cantDrop) {
    // classNames = 'rounded shadow-lg p-2 my-2 bg-gray-200 text-gray-300';
    wrapperClass = 'opacity-50';
  }

  // const canDrop = overIndex === 0 && over.data;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: cantDrop ? 'not-allowed' : isDragging ? 'grabbing' : 'grab',
    zIndex: isDragging ? 10 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} className={classNames} {...attributes} {...listeners}>
      <span className={wrapperClass}>{children}</span>
    </div>
  );
};

// export default SortableItem;
