/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminCreateUser = /* GraphQL */ `
  mutation AdminCreateUser($input: AdminCreateUserInput!) {
    adminCreateUser(input: $input) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      disabledReason
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const adminCreateLeo = /* GraphQL */ `
  mutation AdminCreateLeo($input: AdminCreateLEOInput!) {
    adminCreateLeo(input: $input) {
      id
      identityId
      email
      firstName
      lastName
      country
      state
      zip
      agency
      position
      department
      city
      downloads
      userStatus
      disabledReason
      _deleted
      lastSignInAt
      lastDownloadedAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      disabledReason
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserRecord = /* GraphQL */ `
  mutation UpdateUserRecord($id: String!, $input: UpdateLEOInput!) {
    updateUserRecord(id: $id, input: $input) {
      id
      identityId
      email
      firstName
      lastName
      country
      state
      zip
      agency
      position
      department
      city
      downloads
      userStatus
      disabledReason
      _deleted
      lastSignInAt
      lastDownloadedAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const submitCertification = /* GraphQL */ `
  mutation SubmitCertification($certificatePath: String!) {
    submitCertification(certificatePath: $certificatePath) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      disabledReason
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserType = /* GraphQL */ `
  mutation UpdateUserType($id: String!, $type: UserType!) {
    updateUserType(id: $id, type: $type) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      disabledReason
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const approveUser = /* GraphQL */ `
  mutation ApproveUser($id: String!) {
    approveUser(id: $id) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      disabledReason
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const enableUser = /* GraphQL */ `
  mutation EnableUser($id: String!) {
    enableUser(id: $id) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      disabledReason
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($id: String!, $disableReason: String!) {
    disableUser(id: $id, disableReason: $disableReason) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      disabledReason
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      disabledReason
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const addActiveTimeS = /* GraphQL */ `
  mutation AddActiveTimeS($input: AddActiveTimeInput!) {
    addActiveTimeS(input: $input)
  }
`;
export const createCase = /* GraphQL */ `
  mutation CreateCase($input: CreateCaseInput!) {
    createCase(input: $input) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateCase = /* GraphQL */ `
  mutation UpdateCase($input: UpdateCaseInput!) {
    updateCase(input: $input) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const employeeUpdateCase = /* GraphQL */ `
  mutation EmployeeUpdateCase($input: EmployeeUpdateCaseInput!) {
    employeeUpdateCase(input: $input) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteCase = /* GraphQL */ `
  mutation DeleteCase($id: String!) {
    deleteCase(id: $id) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const nullifyCase = /* GraphQL */ `
  mutation NullifyCase($id: String!) {
    nullifyCase(id: $id) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const setCasePhoto = /* GraphQL */ `
  mutation SetCasePhoto($input: SetCasePhotoInput!) {
    setCasePhoto(input: $input) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const setCaseReportBreakpoints = /* GraphQL */ `
  mutation SetCaseReportBreakpoints($input: SetBreakpointsInput!) {
    setCaseReportBreakpoints(input: $input) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const submitCase = /* GraphQL */ `
  mutation SubmitCase($input: SubmitCaseInput!) {
    submitCase(input: $input) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const assignCase = /* GraphQL */ `
  mutation AssignCase($input: AssignCaseInput!) {
    assignCase(input: $input) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const unassignCase = /* GraphQL */ `
  mutation UnassignCase($input: UnassignCaseInput!) {
    unassignCase(input: $input) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const unapproveCase = /* GraphQL */ `
  mutation UnapproveCase($id: String!) {
    unapproveCase(id: $id) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const approveCase = /* GraphQL */ `
  mutation ApproveCase($id: String!) {
    approveCase(id: $id) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const returnCase = /* GraphQL */ `
  mutation ReturnCase($id: String!) {
    returnCase(id: $id) {
      id
      caseNumber
      sequentialNumber
      tempCaseNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          phoneNumber
          phoneType
          dateOfBirth
          email
          cashtag
          escortSiteTitle
          adLocation
          adDate
        }
        description
        author
        previousSourceId
      }
      sourceOrder
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const mergeCases = /* GraphQL */ `
  mutation MergeCases($input: MergeCasesInput!) {
    mergeCases(input: $input) {
      sortedCaseNumbers
      mergedParentId
    }
  }
`;
export const removeFromMergedCase = /* GraphQL */ `
  mutation RemoveFromMergedCase($input: RemoveFromMergedCaseInput!) {
    removeFromMergedCase(input: $input)
  }
`;
export const updateMergedCaseSources = /* GraphQL */ `
  mutation UpdateMergedCaseSources($input: UpdateMergedCaseSourcesInput!) {
    updateMergedCaseSources(input: $input)
  }
`;
export const generateReportPdf = /* GraphQL */ `
  mutation GenerateReportPdf($id: String!) {
    generateReportPdf(id: $id)
  }
`;
export const shareMfaCredentials = /* GraphQL */ `
  mutation ShareMfaCredentials($email: String!) {
    shareMfaCredentials(email: $email)
  }
`;
export const downloadCase = /* GraphQL */ `
  mutation DownloadCase($input: DownloadCaseInput) {
    downloadCase(input: $input) {
      preSignedUrl
    }
  }
`;
export const adminDownloadCase = /* GraphQL */ `
  mutation AdminDownloadCase($input: AdminDownloadCaseInput!) {
    adminDownloadCase(input: $input) {
      preSignedUrl
    }
  }
`;
export const emailCaseFile = /* GraphQL */ `
  mutation EmailCaseFile($email: String!, $caseId: String!) {
    emailCaseFile(email: $email, caseId: $caseId)
  }
`;
export const createTutorial = /* GraphQL */ `
  mutation CreateTutorial($input: CreateTutorialInput!) {
    createTutorial(input: $input) {
      s3Key
      thumbnailS3Key
      time
      title
      description
      dateUploaded
      author
      authorName
      category
    }
  }
`;
export const updateTutorial = /* GraphQL */ `
  mutation UpdateTutorial($input: UpdateTutorialInput!) {
    updateTutorial(input: $input) {
      s3Key
      thumbnailS3Key
      time
      title
      description
      dateUploaded
      author
      authorName
      category
    }
  }
`;
export const deleteTutorial = /* GraphQL */ `
  mutation DeleteTutorial($s3Key: String!) {
    deleteTutorial(s3Key: $s3Key) {
      s3Key
      thumbnailS3Key
      time
      title
      description
      dateUploaded
      author
      authorName
      category
    }
  }
`;
export const createNotificationCallback = /* GraphQL */ `
  mutation CreateNotificationCallback($input: CreateNotificationInput!) {
    createNotificationCallback(input: $input) {
      id
      title
      message
      type
      recipient
      createdAt
      updatedAt
    }
  }
`;
export const createUserNotification = /* GraphQL */ `
  mutation CreateUserNotification($input: CreateNotificationInput!) {
    createUserNotification(input: $input)
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      error
      createdCount
    }
  }
`;
export const createVolunteerMessage = /* GraphQL */ `
  mutation CreateVolunteerMessage($input: CreateMessageInput!) {
    createVolunteerMessage(input: $input) {
      error
      createdCount
    }
  }
`;
export const unreadMessage = /* GraphQL */ `
  mutation UnreadMessage($id: ID!) {
    unreadMessage(id: $id) {
      id
      title
      message
      subject
      type
      recipient
      recipientAlias
      recipientName
      recipientCount
      recipientMeta {
        recipientCount
        seenCount
        readCount
        users {
          id
          alias
          name
          seenAt
        }
      }
      sender
      senderAlias
      senderName
      senderGroups
      recipientGroups
      createRequestId
      replyTo
      readAt
      seenAt
      adminFlaggedAt
      assignedTo
      assignedToName
      createdAt
      updatedAt
      replyPath
      rootMessageId
      attributes {
        startDate
        endDate
        customTitle
        includeMessage
        fileAttachments {
          name
          path
          size
          type
        }
      }
    }
  }
`;
export const readMessage = /* GraphQL */ `
  mutation ReadMessage($id: ID!) {
    readMessage(id: $id) {
      id
      title
      message
      subject
      type
      recipient
      recipientAlias
      recipientName
      recipientCount
      recipientMeta {
        recipientCount
        seenCount
        readCount
        users {
          id
          alias
          name
          seenAt
        }
      }
      sender
      senderAlias
      senderName
      senderGroups
      recipientGroups
      createRequestId
      replyTo
      readAt
      seenAt
      adminFlaggedAt
      assignedTo
      assignedToName
      createdAt
      updatedAt
      replyPath
      rootMessageId
      attributes {
        startDate
        endDate
        customTitle
        includeMessage
        fileAttachments {
          name
          path
          size
          type
        }
      }
    }
  }
`;
export const unflagMessage = /* GraphQL */ `
  mutation UnflagMessage($id: ID!) {
    unflagMessage(id: $id) {
      id
      title
      message
      subject
      type
      recipient
      recipientAlias
      recipientName
      recipientCount
      recipientMeta {
        recipientCount
        seenCount
        readCount
        users {
          id
          alias
          name
          seenAt
        }
      }
      sender
      senderAlias
      senderName
      senderGroups
      recipientGroups
      createRequestId
      replyTo
      readAt
      seenAt
      adminFlaggedAt
      assignedTo
      assignedToName
      createdAt
      updatedAt
      replyPath
      rootMessageId
      attributes {
        startDate
        endDate
        customTitle
        includeMessage
        fileAttachments {
          name
          path
          size
          type
        }
      }
    }
  }
`;
export const flagMessage = /* GraphQL */ `
  mutation FlagMessage($id: ID!) {
    flagMessage(id: $id) {
      id
      title
      message
      subject
      type
      recipient
      recipientAlias
      recipientName
      recipientCount
      recipientMeta {
        recipientCount
        seenCount
        readCount
        users {
          id
          alias
          name
          seenAt
        }
      }
      sender
      senderAlias
      senderName
      senderGroups
      recipientGroups
      createRequestId
      replyTo
      readAt
      seenAt
      adminFlaggedAt
      assignedTo
      assignedToName
      createdAt
      updatedAt
      replyPath
      rootMessageId
      attributes {
        startDate
        endDate
        customTitle
        includeMessage
        fileAttachments {
          name
          path
          size
          type
        }
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($id: ID!) {
    deleteMessage(id: $id) {
      id
      title
      message
      subject
      type
      recipient
      recipientAlias
      recipientName
      recipientCount
      recipientMeta {
        recipientCount
        seenCount
        readCount
        users {
          id
          alias
          name
          seenAt
        }
      }
      sender
      senderAlias
      senderName
      senderGroups
      recipientGroups
      createRequestId
      replyTo
      readAt
      seenAt
      adminFlaggedAt
      assignedTo
      assignedToName
      createdAt
      updatedAt
      replyPath
      rootMessageId
      attributes {
        startDate
        endDate
        customTitle
        includeMessage
        fileAttachments {
          name
          path
          size
          type
        }
      }
    }
  }
`;
export const assignMessage = /* GraphQL */ `
  mutation AssignMessage($input: AssignMessageInput!) {
    assignMessage(input: $input) {
      id
      title
      message
      subject
      type
      recipient
      recipientAlias
      recipientName
      recipientCount
      recipientMeta {
        recipientCount
        seenCount
        readCount
        users {
          id
          alias
          name
          seenAt
        }
      }
      sender
      senderAlias
      senderName
      senderGroups
      recipientGroups
      createRequestId
      replyTo
      readAt
      seenAt
      adminFlaggedAt
      assignedTo
      assignedToName
      createdAt
      updatedAt
      replyPath
      rootMessageId
      attributes {
        startDate
        endDate
        customTitle
        includeMessage
        fileAttachments {
          name
          path
          size
          type
        }
      }
    }
  }
`;
export const createAdminNotifications = /* GraphQL */ `
  mutation CreateAdminNotifications($input: CreateAdminNotificationsInput!) {
    createAdminNotifications(input: $input)
  }
`;
export const clearNotificationCount = /* GraphQL */ `
  mutation ClearNotificationCount {
    clearNotificationCount
  }
`;
export const generateAlias = /* GraphQL */ `
  mutation GenerateAlias($input: GenerateAliasInput) {
    generateAlias(input: $input) {
      aliases
      error
    }
  }
`;
